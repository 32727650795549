.hero {
    height: calc(100vh - var(--global-header-height));
    display: flex;
    flex-direction: column;
    position: relative;
}

.picture {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}

.container {
    width: var(--global-container-width);
    max-width: var(--global-container-max-width);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
}

.heading {
    padding-left: calc(2 * var(--gap));
    font-size: var(--font-size-3);
    line-height: var(--line-height-md);
    font-weight: 900;
    border-left: 12px solid hsl(var(--color-gold));

    @media (min-width: 600px) {
        font-size: var(--font-size-4);
    }

    @media (min-width: 900px) {
        font-size: var(--font-size-5);
    }
}

@keyframes header-scroll {
    0% {
        transform: translate(-50%, 0);
    }

    50% {
        transform: translate(-50%, -10px);
    }

    100% {
        transform: translate(-50%, 0);
    }
}

.arrow {
    width: 32px;
    height: 18px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    cursor: pointer;
    background: url('/icons/icon_arrow-down.png') no-repeat center;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.75));
    transform: translateX(-50%);
    animation: header-scroll 2s ease-in-out infinite;

    @media (min-width: 900px) {
        display: none;
    }
}
